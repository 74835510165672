.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInput-underline {
  border-bottom: 2px solid #000 !important;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #000 !important;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .makeStyles-cardContainer-3 {
    width: 80% !important;
    height: 300px;
    display: flex;
    padding: 2rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}


@media screen and (min-width: 600px) {
  .makeStyles-cardContainer-3 {
    width: 400px !important;
  }
}
